// Variables
@import "../variables";

// Colors
$primary-color: #37c2df;
$black-color: #000b08;
$white-color: #eff9f6;

// Rest
@import "../frontend";
